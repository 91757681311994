//
// Contents
//

.section {
	+ .section {
		margin-top: $grid-gutter-width !important;
	}
}

.section-title {
	padding-left: $headings-margin-bottom * .5;
	border-left: solid 3px $primary;
	@include font-size($font-size-base);
}

// Main contents

.main {
	.card {
		border: 0;
		background-color: transparent;
	}
	
	.card-header {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
		
		.badge {
			margin-bottom: $paragraph-margin-bottom;
		}
	}
	
	.card-footer {
		background-color: $gray-200;
		@include border-radius($border-radius);
	}
	
	.card-body {
		padding-left: 0;
		padding-right: 0;
		@include font-size($font-size-lg);
	}
	
	.card-title {
		@include font-size($h1-font-size);
	}
	
	.h1 {
		position: relative;
		padding: $spacer;
		border: 3px solid $primary;
		@include font-size($h2-font-size);
		@include border-radius($border-radius);
		
		&::before,
		&::after {
			content: "";
			position: absolute;
			left: $spacer;
			width: 0;
			height: 0;
			border-width: 14px 12px 0 12px;
			border-style: solid;
		}
		
		&::before {
			bottom: -14px;
			border-color: $primary transparent transparent transparent;
		}
		
		&::after {
			bottom: -10px;
			border-color: $white transparent transparent transparent;
		}
	}
	
	.h2 {
		position: relative;
		padding: $spacer $spacer $spacer $spacer * 2.5;
		border: solid 3px $gray-200;
		@include font-size($h3-font-size);
		@include border-radius($border-radius);
		
		&::after {
			content: "";
			position: absolute;
			top: $spacer;
			left: $spacer;
			width: 8px;
			height: $spacer * 2;
			background-color: $primary;
			@include border-radius($border-radius);
		}
	}
	
	.h3 {
		position: relative;
		padding-left: $spacer * 1.25;
		@include font-size($h4-font-size);
		
		&::before {
			content: "";
			position: absolute;
			top: $spacer * .25;
			left: 0;
			width: 5px;
			height: $spacer * 1.5;
			background-color: $purple;
			@include border-radius($border-radius);
		}
	}
}

// Indexes

.indexes {
	margin: 0 auto $paragraph-margin-bottom;
	background-color: $gray-100;
	overflow: hidden;
	@include font-size($font-size-base);
	@include border-radius($border-radius);
	
	a {
		display: block;
	}
	
	ol {
		margin: $spacer * .5 $spacer;
		list-style: none;
		
		li {
			&:not(:last-of-type) {
				border-bottom: dashed $border-width $gray-300;
			}
			
			a {
				padding-top: $spacer * .5;
				padding-bottom: $spacer * .5;
			}
			
			ol {
				margin: 0;
				border-top: dashed $border-width $gray-300;
				
				li {
					padding-left: $spacer * 1.5;
				}
			}
		}
	}
	
	.collapse-toggler {
		padding: $spacer * .5 0;
		text-decoration: none;
		text-align: center;
		color: $white;
		background-color: $purple;
	}
	
	@include media-breakpoint-up(md) {
		width: 80%;
	}
}

// Profile

.profile {
	display: flex;
	align-items: flex-start;
	
	&-avatar {
		width: 100px;
	}
	
	&-content {
		position: relative;
		flex: 1;
		margin-left: $spacer;
		padding: $spacer;
		background-color: $white;
		@include border-radius($border-radius);
		
		&::before {
			content: "";
			position: absolute;
			top: $spacer * 3;
			left: -8px;
			display: block;
			width: 0;
			height: 0;
			border-style: solid;
			border-color: transparent $white transparent transparent;
			border-width: 8px 8px 8px 0;
		}
		
		p {
			margin-bottom: $paragraph-margin-bottom * .5;
			
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

// Breadcrumbs

.breadcrumb {
	padding-top: $grid-gutter-width * .5;
	padding-bottom: $grid-gutter-width * .5;
	
	a {
		text-decoration: none;
		color: $white;
	}
}

//
// Commons
//

.anchor {
	padding-top: $spacer * 2;
}

.avatar {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: $paragraph-margin-bottom;
	text-align: center;
	
	img {
		background-color: $primary;
		@include border-radius($border-radius-pill);
	}
}

// Nav

.nav {
	&.flex-column {
		.nav-item {
			margin-bottom: $nav-link-padding-y;
			padding-bottom: $nav-link-padding-y;
			border-bottom: solid $border-width $border-color;
			
			&:last-child {
				margin-bottom: 0;
				padding-bottom: 0;
				border-bottom: 0;
			}
		}
		
		.nav-link {
			padding: 0;
			color: $body-color;
			@include transition($transition-base);
			
			&:hover {
				transform: translateX(3px);
				color: $teal;
			}
		}
		
		.footer & {
			.nav-item {
				border-bottom: 0;
			}
			
			.nav-link {
				color: $gray-600;
			}
		}
	}
}

// Share

.share {
	display:flex;
	align-items: center;
	justify-content: center;
	
	a {
		display: block;
		margin-left: $spacer * .25;
		margin-right: $spacer * .25;
		padding: $spacer * .5;
		text-align: center;
		line-height: 1;
	}
	
	.navbar & {
		a {
			background-color: $white;
			@include border-radius($border-radius);
			
			&:not(:hover),
			&:not(:focus) {
				color: $primary;
			}
		}
	}
	
	.main & {
		margin-left: -$spacer * .25;
		margin-right: -$spacer * .25;
		margin-bottom: $paragraph-margin-bottom;
		
		a {
			flex: 1 1 auto;
			@include border-radius($border-radius);
		}
	}
}

.share-link {
	@include transition($transition-base);
	
	&-1 {
		color: $cyan;
		background-color: $white;
		border: solid 2px rgba($cyan, .5);
		
		&:hover {
			color: $white;
			background-color: $cyan;
		}
	}
	
	&-2 {
		color: $purple-600;
		background-color: $white;
		border: solid 2px rgba($purple-600, .5);
		
		&:hover {
			color: $white;
			background-color: $purple-600;
		}
	}
	
	&-3 {
		color: $blue-600;
		background-color: $white;
		border: solid 2px rgba($blue-600, .5);
		
		&:hover {
			color: $white;
			background-color: $blue-600;
		}
	}
	
	&-4 {
		color: $red;
		background-color: $white;
		border: solid 2px rgba($red, .5);
		
		&:hover {
			color: $white;
			background-color: $red;
		}
	}
	
	&-5 {
		color: $green;
		background-color: $white;
		border: solid 2px rgba($green, .5);
		
		&:hover {
			color: $white;
			background-color: $green;
		}
	}
}
