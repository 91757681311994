* {
	word-break: break-all;
}

button,
.btn {
	cursor: pointer;
	
	&:focus {
		outline: none !important;
	}
}

img,
canvas {
	max-width: 100%;
}

ol,
ul {
	list-style-position: inside;
	padding-left: 0;
}

mark,
.mark {
	background: linear-gradient(transparent 70%, $mark-bg 70%);
}

.bg-transparent {
	background-color: rgba($white, .25) !important;
}

//
// Container
//

.container-fluid {
	padding-left: $grid-gutter-width;
	padding-right: $grid-gutter-width;
	max-width: 1200px;
}

//
// Alerts
//

.alert-with-icon {
	display: flex;
	
	.alert-icon {
		padding-right: $spacer;
	}
}

//
// Badge
//

.badge {
	vertical-align: middle;
	@include border-radius($border-radius-pill);
	
	.h1 &,
	.h2 &,
	.h3 &,
	.h4 &,
	.h5 &,
	.h6 & {
		margin-right: $spacer * .5;
	}
}

//
// Buttons
//

@each $color, $value in $theme-colors {
	.btn-#{$color} {
		@include button-variant($value, $value, $white);
	}
}

//
// Breadcrumb
//

.breadcrumb {
	ol {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: $breadcrumb-margin-bottom;
		padding: $breadcrumb-padding-y $breadcrumb-padding-x;
		list-style: none;
		background-color: transparent;
		@include font-size($breadcrumb-font-size);
	}
}

//
// Card
//

.card-header,
.card-body,
.card-footer {
	:last-child {
		margin-bottom: 0;
	}
}

.card-header {
	border-bottom: 0;
}

.card-footer {
	border-top: 0;
}

.card-title {
	@include font-size($font-size-lg);
}

.card-img {
	text-align: center;
	
	img {
		width: 100%;
	}
}

//
// Nav
//

.navbar-toggler {
	position: relative;
	width: $navbar-toggler-width;
	height: $navbar-toggler-height;
	
	&:focus {
		box-shadow: none;
	}
	
	.navbar-toggler-icon {
		position: absolute;
		left: 0;
		width: 100%;
		height: 2px;
		background-color: $primary;
		@include border-radius($border-radius);
		@include transition($transition-base);

		&:nth-of-type(1) {
			top: 0;
		}

		&:nth-of-type(2) {
			top: calc(50% - #{$navbar-toggler-border-width}/2);
		}

		&:nth-of-type(3) {
			bottom: 0;
		}
	}
	
	&:not(.collapsed) {
		.navbar-toggler-icon {
			&:nth-of-type(1) {
				transform: translateY(8px) rotate(-45deg);
			}

			&:nth-of-type(2) {
				opacity: 0;
			}

			&:nth-of-type(3) {
				transform: translateY(-8px) rotate(45deg);
			}
		}
	}
}

//
// Pagination
//

.pagination {
	margin-bottom: 0;
	justify-content: center;
}

.page-item {
	margin-left: $spacer * .25;
	margin-right: $spacer * .25;
	
	.page-link {
		width: 40px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		@include border-radius($pagination-border-radius);
		@include transition($transition-base);
	}
}

//
// Tables
//

.table-responsive {
	margin-bottom: $spacer;
	
	.table {
		margin-bottom: 0;
	}
}

//
// Forms
//

.input-group {
	.form-control,
	.btn {
		@include border-radius($border-radius-pill);
	}
	
	.btn {
		border: solid $input-border-width $input-border-color;
		border-left: 0;
		background-color: $input-bg;
		line-height: 1;
	}
}

//
// Misc
//

.blockquote {
	position: relative;
	padding: $spacer * .5 $spacer $spacer * .5 $spacer * 2.5;
	background-color: $gray-100;
	
	&::before {
		position: absolute;
		top: 0;
		left: 0;
		margin: $spacer * .5;
		@include font-size($h3-font-size);
	}
}
